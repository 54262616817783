import React, { useState, useEffect } from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { useSelector } from "react-redux";
import DefaultButton from "../../../../Buttons/DefaultButton";
import baseStyles from "../index.module.scss";
import styles from "./ConnectingBidddy.module.scss";
import { useAppDispatch } from "../../../../../store";
import { selectUserInfo } from "../../../../../store/user/selectors";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../store/user/actions";
import Axios from "../../../../../extensions/Axios";
import { baseURL } from "../../../../../utils/axios";
import { event } from "../../../../../lib/ga";

const DrawingSkateboard =
  "https://imgix.cosmicjs.com/00fb3cd0-3f88-11ec-99ca-fbde970234a8-bidfootsketchskateboard-1.png";

const ConnectingBidddy = () => {
  const dispatch = useAppDispatch();
  const [linkUrl, setLinkUrl] = useState(null);
  const userInfo = useSelector((state) => selectUserInfo(state));
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
    (async () => {
      const currentStateObject = {
        type: "NEW_AUTH_FLOW_CONNECTING_BIDDDY",
        navigationPath: "/dashboard/getting-started/ready-to-roll/",
        location: "NEW_AUTH_FLOW_CONNECTING_BIDDDY",
        redirectURL: "/dashboard/getting-started/ready-to-roll/",
      };
      // Handle stuff.
      const response = await Axios.get(
        `${baseURL}/link/instagram?format=json&state=${encodeURIComponent(
          JSON.stringify(currentStateObject)
        )}`
      );
      setLinkUrl(response.data.url);
    })();
  }, []);

  const openUrl = (url) => {
    event({
      action: "instagram_auth_attempt",
      params: {},
    });
    setLocalLoading(true);
    window.location.href = url;
  };

  const connect = () => {
    setLocalLoading(true);
    if (typeof window !== "undefined" && userInfo && linkUrl) {
      openUrl(linkUrl);
    }
  };

  return (
    <div className={baseStyles.baseAuthFlowContainer}>
      <div className={baseStyles.topContainer}>
        <Imgix
          src={`${DrawingSkateboard}?auto=format`}
          height={165}
          width={172}
          htmlAttributes={{
            alt: "Set Up Account",
            src: `${DrawingSkateboard}?blur=100&px=2&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
        <h3>Ready?</h3>
        <h1>
          Connecting to
          <br /> Instagram is Easy
        </h1>
        <p>
          To use Bidddy, you’ll need to connect Bidddy to an Instagram account.
          To help it go smoothly, you will need the following:
        </p>
        <div className={styles.whatYouNeedSection}>
          <h4>Instagram Business or Creator Account</h4>
          <p>
            Your instagram account is either set up as a Personal, Business or
            Creator Account. Need help learning how to switch your account?{" "}
            <a
              href="/learn/switching-an-account/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              Click here.
            </a>
          </p>
        </div>
        <div className={styles.whatYouNeedSection}>
          <h4>Facebook Page Linked to Account</h4>
          <p>
            Instagram uses Facebook to connect. Need help learning how to
            connect a Facebook Page to an Instagram Account?{" "}
            <a
              href="/learn/how-to-link-facebook-page/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              Click here.
            </a>
          </p>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <DefaultButton
          onClick={() => connect()}
          title="NEXT"
          loading={localLoading}
        />
      </div>
    </div>
  );
};

export default ConnectingBidddy;
