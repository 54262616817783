import React from "react";
import { navigate } from "gatsby";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import { AuthProvider } from "../../../components/Auth/auth";
import LoginLayout from "../../../components/LoginLayout";
import ConnectingBidddy from "../../../components/PageComponents/Dashboard/NewAuthFlow/ConnectingBidddy/ConnectingBidddy";

const ConnectingBidddyPage = () => (
  <AuthProvider>
    <SignUpHeader
      hideBack={false}
      onBack={() => navigate("/dashboard/getting-started/account-set-up/")}
      title="Connect To Instagram"
      onClose={() => navigate("/dashboard/")}
    />
    <LoginLayout hideHeader title="Bidddy - Connecting Bidddy">
      <ConnectingBidddy />
    </LoginLayout>
  </AuthProvider>
);

export default ConnectingBidddyPage;
